import LocationObj from "../LocationObj";
import CourtObj from "../CourtObj";
import ReservationObj from "../ReservationObj";

class InformationDTO {
  id;
  createdAt;
  memberId;
  status;
  naver;
  court;
  location;
  reservations;
  constructor(data) {
    this.id = data.id;
    this.createdAt = data.createdAt;
    this.memberId = data.memberId;
    this.status = data.status;
    this.naver = data.naver;
    this.court = new CourtObj(data.information.court);
    this.location = new LocationObj(data.information.location);
    this.reservations = data.information.reservations.map((obj) => new ReservationObj(obj));
  }
}

export default InformationDTO;
