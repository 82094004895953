import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useState, useEffect } from "react";
import { getCourtLocationid, getCourtLocationidDate } from "../../utils/appUtil";
import { useQuery } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import CourtDTO from "../model/response/CourtDTO";
import PopupFail from "../Feedback/PopupFail";

const CssSelectField = styled(Select)({
    "&": {
        height: "40px",
        width: "100%",
    },
});

const CssItemField = styled(MenuItem)({
    "&": {
        fontSize: "0.9rem",
        height: "32px",
        width: "100%",
    },
});

const CourtInput = (props) => {
    //useState
    const { location, court, setCourt, pdate } = props;

    let [courts, setCourts] = useState([]);
    let [courtId, setCourtId] = useState("");
    let [E_open, setE_Open] = useState(false);
    // YYYY-MM-DD 형식으로 변환
    const formattedDate = pdate.format("YYYY-MM-DD");

    const [cookies, ,] = useCookies(["accessToken"]);

    //useQuery
    const getCourtLocationidDateAPI = useQuery({
        queryKey: ["getCourtLocationidDateAPI"],
        queryFn: () => getCourtLocationidDate({ locationId: location, pdate: formattedDate }),
        enabled: false,
    });

    //useEffect
    useEffect(() => {
        if (location !== "") {
            getCourtLocationidDateAPI
                .refetch()
                .then((data) => {
                    if (data.data.data.code === 200) {
                        let result__ = data.data.data.data.courts.map((court) => new CourtDTO(court));
                        setCourts(result__);
                        console.log(result__);
                        if (result__.length > 0) {
                            setCourt(result__[0]); // court 상태 업데이트
                            setCourtId(result__[0].id);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setCourts([]);
                    setE_Open(true);
                });
        }
    }, [location, pdate]);

    const handleStadiumChange = (e) => {
        let __court = courts.find((court) => court.id === e.target.value);
        setCourt(__court);
        setCourtId(__court.id);
    };

    return (
        <>
            <CssSelectField
                labelId="stadium-select-label"
                id="stadium-select"
                displayEmpty
                value={courtId}
                onChange={handleStadiumChange}
            >
                <CssItemField disabled value="">
                    <em>이용하실 코트를 선택해 주세요</em>
                </CssItemField>
                {getCourtLocationidDateAPI.isSuccess
                    ? courts.map((court) => (
                          <CssItemField key={court.name} value={court.id}>
                              {court.name}
                          </CssItemField>
                      ))
                    : null}
            </CssSelectField>
            <PopupFail
                open={E_open}
                setOpen={setE_Open}
                msg={"다른 지점을 선택해 주세요"}
                title={"미오픈 매장"}
            ></PopupFail>
        </>
    );
};

export default CourtInput;
