import "../../assets/css/Reservation/Finish.css";
import HomeLogo from "../../../src/assets/img/home_logo_ex.png";
import SuccessAnimation from "../../components/Animation/SuccessAnimation";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Finish = (props) => {
  const location = useLocation();

  const orderDTO = location.state?.orderDTO;
  //useNavigate
  const navigate = useNavigate();
  //useState
  let [start, setStart] = useState(true);

  //useEffect
  useEffect(() => {
    if (!location.state) {
      navigate("/reservation");
    }
  }, [location.state]);

  // console.log(orderDTO);

  return (
    <>
      <div className="common-content">
        <img className="home-logo" src={HomeLogo} alt="homelogo"></img>
      </div>
      <div className="finish-content">
        <div>
          <div className="infor-box b-border">
            <SuccessAnimation open={start}></SuccessAnimation>
            <h4 className="par">예약이 완료되었습니다.</h4>
            <p>예약정보 확인 - 마이페이지</p>
            {/* <div className="left-align">
              <p>지점 : 명동점</p>
              <p>코트 : A코트</p>
              <p>날짜 : 2023년 3월 3일</p>
              <p>시간 : 19:00 - 20:00</p>
            </div> */}
          </div>
          <div className="infor-box">
            <div className="left-align">
              <p>예약하신 시간대에 매장을 방문해주세요.</p>
              <p>
                예약시간 내에는 자유롭게 코트를
                <br />
                이용하실 수 있습니다.
              </p>
            </div>
          </div>
        </div>
        <button
          className="callback-btn"
          onClick={() => {
            navigate("/");
          }}
        >
          홈으로
        </button>
      </div>
    </>
  );
};

export default Finish;
