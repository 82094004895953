import axios from "axios";
import Cookies from "js-cookie";

/**
 * Before Request
 */
axios.interceptors.request.use(
  async (config) => {
    // config.headers = config.headers ?? {};
    // if (config.data instanceof FormData) {
    //   config.headers["Content-Type"] = "multipart/form-data";
    // } else {
    //   config.headers["Content-Type"] = "application/json";
    // }
    config.headers.Authorization = `Bearer ${Cookies.get("accessToken")}`;
    return config;
  },
  (error) => {
    // ...
    return Promise.reject(error);
  }
);

/**
 * After Response
 */
axios.interceptors.response.use(
  async (success) => {
    const code = success.data.code;
    if (code === 403) {
      // 현재 날짜 이전의 날짜를 생성하여 쿠키를 만료시킵니다.
      const pastDate = new Date(0);

      // 쿠키 만료일을 GMT 형식으로 설정합니다.
      const expires = "expires=" + pastDate.toUTCString();

      // document.cookie를 사용하여 쿠키를 삭제합니다.
      document.cookie = "accessToken" + "=; " + expires;

      alert("세션이 만료되었습니다. 다시 로그인하세요.");
      window.location.href = "/";
    }
    return success;
  },
  async (error) => {
    return error;
  }
);
export default axios;
