import HomeLogo from "../../src/assets/img/home_logo_ex.png";
import "../assets/css/Reservation.css";
import { useState, useEffect, useCallback } from "react";
import StadiumInput from "../components/Input/StadiumInput";
import DateInput from "../components/Input/DateInput";
import ScheduleInput from "../components/Input/ScheduleInput";
import CourtInput from "../components/Input/CourtInput";
import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import { getMember } from "../utils/appUtil";
import PaymentModal from "../components/modal/PaymentModal";
import OrderDTO from "../components/model/request/OrderDTO";
import ReservationDTO from "../components/model/request/ReservationDTO";
import PaymentDTO from "../components/model/request/PaymentDTO";
import { initIamport } from "../utils/Iamport";
import CouponBox from "../components/CouponBox";

const Reservation = () => {
  //useState
  let [totalPrice, setTotalPrice] = useState("");
  let [location, setLocation] = useState("");
  let [court, setCourt] = useState(null);
  let [pdate, setPdate] = useState(dayjs());
  let [paymentOpen, setPaymentOpen] = useState(false);
  let [member, setMember] = useState(null);
  let [sTime, setSTime] = useState(dayjs("1999-01-01", "YYYY-MM-DD"));
  let [eTime, setETime] = useState(dayjs("2099-01-01", "YYYY-MM-DD"));
  let [done, setDone] = useState(false);
  let [orderDTO, setOrderDTO] = useState(null);
  let [pCoupon, setPCoupon] = useState(null);
  const [cookies, , removeCookie] = useCookies(["accessToken"]);
  let [discountPrice, setDiscountPrice] = useState(0);

  const getMemberAPI = useQuery({
    queryKey: ["getMemberAPI"],
    queryFn: () => getMember(),
    enabled: !!cookies.accessToken,
  });

  //useEffect
  useEffect(() => {
    initIamport();
  }, []);

  useEffect(() => {
    setTotalPrice("");
  }, [court]);

  useEffect(() => {
    if (getMemberAPI.isSuccess) {
      setMember(getMemberAPI.data.data.data);
    }
  }, [getMemberAPI]);

  /**
   * 총 금액 / 쿠폰 변경 시마다 할인 계산
   */

  useEffect(() => {
    if (pCoupon) {
      const discountedPrice = calculateDiscountedPrice(totalPrice, pCoupon);
      setDiscountPrice(discountedPrice);
    } else {
      setDiscountPrice(0);
    }
  }, [totalPrice, pCoupon]);

  /**
   * 쿠폰 할인 함수
   */
  const calculateDiscountedPrice = (totalPrice, coupon) => {
    if (!coupon) return 0;

    if (coupon.coupon.type === "amount") {
      return coupon.coupon.value;
    } else if (coupon.coupon.type === "percent") {
      return totalPrice * (coupon.coupon.value / 100);
    }

    return 0; // 쿠폰 타입이 정의되지 않은 경우
  };

  /**
   * 예약 정보 반환 함수
   */
  const generateReservations = useCallback(() => {
    let reservations = [];
    if (done) {
      let start = sTime.clone();
      let end = eTime.clone().add(30, "minutes"); // eTime에 30분 추가
      while (start.isBefore(end)) {
        let reservationDTO = new ReservationDTO({
          courtId: court.id,
          startTime: start.format("YYYY-MM-DD HH:mm:ss"),
          endTime: start.clone().add(30, "minutes").format("YYYY-MM-DD HH:mm:ss"),
        });
        reservations.push(reservationDTO);
        start = start.add(30, "minutes");
      }
    }

    return reservations;
  }, [sTime, eTime]);

  /**
   * 포트원 결제 모듈 실행
   */
  const handlePayment = (e) => {
    console.log(court);
    if (Number(totalPrice) === 0) {
      alert("예약시간을 선택해 주세요");
    } else if (!court) {
      alert("이용하실 코트를 선택해 주세요");
    } else {
      let reservations = generateReservations();

      console.log("reservations : ", reservations);

      let paymentDTO = new PaymentDTO({
        totalPrice: totalPrice,
        discountPrice: discountPrice,
        type: "card",
      });

      //쿠폰 미선택 시, memberCouponid null로 설정
      let memberCouponId = pCoupon ? pCoupon.id : null;

      let __orderDTO = new OrderDTO({
        memberId: member.id,
        memberCouponId: memberCouponId,
        reservations: reservations,
        payment: paymentDTO,
      });

      console.log(__orderDTO);

      setOrderDTO(__orderDTO);
      setPaymentOpen(true);
    }
  };

  return (
    <>
      <div className="common-content">
        <img className="home-logo" src={HomeLogo} alt="homelogo"></img>
      </div>
      <div className="reserve-content">
        <div className="form-content">
          <div className="form-sub">
            <h3>지점 선택</h3>
            <StadiumInput location={location} setLocation={setLocation}></StadiumInput>
          </div>
          <div className="form-sub">
            <h3>코트 선택</h3>
            <CourtInput location={location} court={court} setCourt={setCourt} pdate={pdate}></CourtInput>
          </div>
          <div className="form-sub">
            <h3>날짜 선택</h3>
            <DateInput pdate={pdate} setPdate={setPdate}></DateInput>
          </div>
          <div className="form-sub">
            <h3>시간 선택</h3>
            <p>예약은 30분 단위로 가능해요</p>
            <ScheduleInput
              court={court}
              totalPrice={totalPrice}
              setTotalPrice={setTotalPrice}
              pdate={pdate}
              sTime={sTime}
              setSTime={setSTime}
              eTime={eTime}
              setETime={setETime}
              done={done}
              setDone={setDone}
            ></ScheduleInput>
          </div>
          {member?.gender !== "machine" && (
            <>
              <div className="form-sub">
                <h3>쿠폰 사용하기</h3>
                <p>사용할 쿠폰을 선택해 주세요</p>
                <CouponBox selectVer={true} setPCoupon={setPCoupon}></CouponBox>
              </div>
              {totalPrice && pCoupon && (
                <div className="form-sub couponsub">
                  <h3>쿠폰 적용 금액</h3>
                  <p>
                    {totalPrice.toLocaleString()}원 -{" "}
                    {pCoupon.coupon.type === "percent"
                      ? `${pCoupon.coupon.value}%`
                      : `${pCoupon.coupon.value.toLocaleString()}원`}{" "}
                    = {(totalPrice - discountPrice).toLocaleString()}원
                  </p>
                </div>
              )}
            </>
          )}
          <div className="form-sub">
            <h3>환불 정책</h3>
            <p>예약취소는 코트이용 시작 1시간 전까지 전액 환불 가능합니다</p>
            <p>환불은 결제대행사/카드사를 거쳐 최대 2주가 걸릴 수 있습니다</p>
            <p>
              코트이용 시작시간 1시간 이내에는 단순변심/개인사정으로 인한 환불이 불가능하니, 예약시간은 신중하게 확인
              부탁드립니다
            </p>
          </div>
        </div>
      </div>
      <button
        className="payment-btn"
        onClick={(e) => {
          handlePayment(e);
        }}
      >
        결제하기
      </button>
      {getMemberAPI.isSuccess ? (
        <PaymentModal
          member={member}
          totalPrice={totalPrice - discountPrice}
          open={paymentOpen}
          setOpen={setPaymentOpen}
          orderDTO={orderDTO}
        ></PaymentModal>
      ) : null}
    </>
  );
};

export default Reservation;
