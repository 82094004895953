import HomeLogo from "../../src/assets/img/home_logo_ex.png";
import "../assets/css/Login.css";
import appConfig from "../config/appConfig";
import PhoneInput from "../../src/components/Input/PhoneInput";
import PasswordInput from "../../src/components/Input/PasswordInput";
import PopupMsg from "../components/Feedback/PopupFail";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import { postIssueToken } from "../utils/appUtil";
import Axios from "../utils/Interceptors";

const Login = () => {
  //useCookies
  const [cookies, setCookie] = useCookies(["accessToken"]);

  //useNavigate
  const navigate = useNavigate();

  //usestate
  let [phone, setPhone] = useState("");
  let [password, setPassword] = useState("");
  let [valPhone, setValPhone] = useState(false);
  let [valPassword, setValPassword] = useState(false);
  let [isCanLogin, setIsCanLogin] = useState(false);
  let [loginOpen, setLoginOpen] = useState(false);
  let [loginMsg, setLoginMsg] = useState("");
  let [loginTitle, setLoginTitle] = useState("");

  //useEffect
  useEffect(() => {
    if (valPhone && valPassword) {
      setIsCanLogin(true);
    } else {
      setIsCanLogin(false);
    }
  }, [valPhone, valPassword]);

  const handleLoginBtn = async (e) => {
    try {
      const result = await postIssueToken({
        phoneNumber: phone,
        password: password,
      });
      if (result.status === 200) {
        const { code, data, msg, success } = result.data;
        if (code === 200) {
          const { accessToken, expirationDate } = data;
          setCookie("accessToken", accessToken, {
            path: "/",
            // domain: `${appConfig.SERVER_IP}`,
            expires: new Date(expirationDate),
            secure: true,
            sameSite: "none",
            // httpOnly: Boolean(appConfig.HTTP_ONLY === "true"),
          });
        } else if (code === 400) {
          setLoginTitle("로그인 실패");
          setLoginMsg(
            `아이디(로그인 전용 아이디) 또는 비밀번호를 잘못 입력했습니다.
             입력하신 내용을 다시 확인해주세요.`
          );
          setLoginOpen(true);
        }
      }
    } catch (error) {
      setLoginTitle("로그인 실패");
      setLoginMsg(`로그인에 문제가 발생했습니다. 관리자에게 문의해 주세요.`);
      setLoginOpen(true);
    }
  };

  const activeEnter = (e) => {
    if (isCanLogin && e.key === "Enter") {
      handleLoginBtn();
    }
  };

  return (
    <>
      <div className="common-content">
        <img className="home-logo" src={HomeLogo} alt="homelogo"></img>
      </div>
      <div className="login-content">
        <p className="content_infor">로그인이 필요한 서비스입니다🎾</p>
        <div className="form-content">
          <div className="form-sub">
            <h3>휴대폰 번호</h3>
            <PhoneInput
              phone={phone}
              setPhone={setPhone}
              setValPhone={setValPhone}
              activeEnter={activeEnter}
            ></PhoneInput>
          </div>
          <div className="form-sub">
            <h3>비밀번호</h3>
            <PasswordInput
              isLogin={true}
              passowrd={password}
              setPassword={setPassword}
              setValPassword={setValPassword}
              activeEnter={activeEnter}
            ></PasswordInput>
          </div>
        </div>
        <div className="reg-route-content">
          <p>회원이 아니세요?</p>
          <button
            className="reg-route-btn"
            onClick={() => {
              navigate("/register");
            }}
          >
            회원가입
          </button>
        </div>
      </div>
      <button
        className={`login-btn`}
        disabled={!isCanLogin}
        onClick={(e) => {
          handleLoginBtn(e);
        }}
      >
        로그인
      </button>
      <PopupMsg open={loginOpen} setOpen={setLoginOpen} msg={loginMsg} title={loginTitle}></PopupMsg>
    </>
  );
};

export default Login;
