class CommonDTO {
  success;
  code;
  msg;
  constructor(data) {
    this.success = data.success || null;
    this.code = data.code || null;
    this.msg = data.msg || null;
  }
}

export default CommonDTO;
