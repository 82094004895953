import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { forwardRef } from "react";
import "../../assets/css/modal/PaymentModal.css";
import Progressbar from "../Feedback/Progressbar";

const CssDialogField = styled(Dialog)({
  "& .MuiPaper-root": {
    boxShadow: "0px 11px 15px -7px rgba(0,0,0,0.2);",
    "& h2": {
      fontWeight: "700",
    },
  },
  "& .MuiDialogContent-root": {
    "& .MuiTypography-root": {
      display: "flex",
      "@media (max-width: 500px)": {
        flexDirection: "column",
      },
    },
  },
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ProgressModal = (props) => {
  //useState
  const { open, setOpen } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <CssDialogField
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>취소 처리 중</DialogTitle>
        <DialogContent>
          <Progressbar />
        </DialogContent>
      </CssDialogField>
    </>
  );
};

export default ProgressModal;
