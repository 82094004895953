import { useLocation } from "react-router-dom";
import HomeLogo from "../../../src/assets/img/home_logo_ex.png";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import "../../assets/css/Reservation/VerifyReservation.css";
import { useEffect } from "react";
const VerifyReservation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // query string을 파싱합니다.
  const queryParams = queryString.parse(location.search);

  // 오류 코드와 메시지가 있는 경우 확인
  const { error_code, error_msg, imp_uid, merchant_uid } = queryParams;

  //useEffect
  useEffect(() => {
    if (!error_code && !error_msg) {
      navigate("/reservation/finish", { state: { orderDTO: { imp_uid, merchant_uid } } });
    }
  }, [queryParams]);

  console.log(queryParams);

  // 오류가 있는 경우, 오류 메시지를 표시합니다.
  return (
    <>
      <div className="common-content">
        <img className="home-logo" src={HomeLogo} alt="homelogo"></img>
      </div>
      <div className="verfiy-content">
        <div>
          <div className="verify-content">
            <div>
              <h3 className="par">결제 실패</h3>
              <div className="error-box">
                <p>{decodeURIComponent(error_msg)}</p>
                <p>{`해당 에러가 지속적으로 발생 시, 관리자에게 문의해 주세요.`}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="button-div">
          <button
            className="callback-btn"
            onClick={() => {
              navigate("/");
            }}
          >
            홈으로
          </button>
        </div>
      </div>
    </>
  );
};

export default VerifyReservation;
