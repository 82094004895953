import CouponObj from "../CouponObj";

class MemberCouponDTO {
  id;
  expirationDate;
  coupon;
  constructor(data) {
    this.id = data.id || null;
    this.expirationDate = data.expirationDate || null;
    this.coupon = data.coupon ? new CouponObj(data.coupon) : null;
  }
}

export default MemberCouponDTO;
