import { configureStore, createSlice } from "@reduxjs/toolkit";

/**
 * NavId : 네비게이션 아이디
 *
 */
let member = createSlice({
  name: "navId",
  initialState: [],
  reducers: {
    setNavId(state, action) {
      if (action.payload === undefined) return [];
      else return [action.payload];
    },
  },
});

/**
 * NavId : 네비게이션 아이디
 *
 */
let navId = createSlice({
  name: "navId",
  initialState: [],
  reducers: {
    setNavId(state, action) {
      if (action.payload === undefined) return [];
      else return [action.payload];
    },
  },
});

/**
 * Testing : 테스팅 중인지 확인 유무
 */
let testing = createSlice({
  name: "testing",
  initialState: false,
  reducers: {
    setTesting(state, action) {
      return action.payload;
    },
  },
});

/**
 * DeviceList : 테스팅 로봇 Mac주소
 */
let deviceList = createSlice({
  name: "deviceList",
  initialState: [],
  reducers: {
    setDeviceList(state, action) {
      const robotList = [];

      for (let i = 1; i <= action.payload; i++) {
        let rb_id_set = "L" + i.toString().padStart(11, "0");
        let rb_id = rb_id_set.match(/.{2}/g).join(".");
        robotList.push({ rb_id });
      }

      return robotList;
    },
  },
});

/**
 * ThreadList : Redis Thread 정보
 */
let threadList = createSlice({
  name: "threadList",
  initialState: [],
  reducers: {
    setThreadList(state, action) {
      return action.payload;
    },
  },
});

export default configureStore({
  reducer: {
    navId: navId.reducer,
    testing: testing.reducer,
    deviceList: deviceList.reducer,
    threadList: threadList.reducer,
  },
});
