import appConfig from "../config/appConfig";
import Axios from "../utils/Interceptors";

/**
 * 1. 회원 정보 저장
 * @returns data
 */
const postSignUp = async (data) => {
  return await Axios.post(`${appConfig.SERVER_IP}/signUp`, data);
};

/**
 * 2. 인증 토큰 발급(로그인)
 * @returns data
 */
const postIssueToken = async (data) => {
  return await Axios.post(`${appConfig.SERVER_IP}/issue/token`, data);
};

/**
 * 3. 예약 가능 매장 전체 조회
 * @returns data
 * list : Array
 ** id : Number
 ** name : String
 ** address : String
 */
const getLocation = async (data) => {
  return await Axios.get(`${appConfig.SERVER_IP}/location`, {});
};

/**
 * 4. 매장의 코트 정보 조회
 * @returns data
 * id : Number
 * name : String
 * address : String
 * courts : Array
 ** id : Number
 ** name : String
 ** description : String
 ** price : Number
 ** status : String
 */
const getCourtLocationid = async (params) => {
  let { locationId } = params;
  return await Axios.get(`${appConfig.SERVER_IP}/location/${locationId}`, {});
};

/**
 * 0602 patch로 추가
 * 4-2. 매장의 코트 정보 조회(날짜/코트별 가격 변경 정책 추가)
 * @returns data
 * id : Number
 * name : String
 * address : String
 * courts : Array
 ** id : Number
 ** name : String
 ** description : String
 ** price : Number
 ** status : String
 */
const getCourtLocationidDate = async (params) => {
  let { locationId, pdate } = params;
  return await Axios.get(`${appConfig.SERVER_IP}/location/${locationId}/${pdate}`, {});
};

/**
 * 5. 코트 별 특정 날짜에 예약된 내역 조회
 * @returns data
 * list : Array
 ** id : Number
 ** courtId : String
 ** startTime : String<DateTime>
 ** endTime : String<DateTime>
 */
const getReservationbyCourtIdAndDate = async (params, data) => {
  let { courtId, reservationDate } = params;
  return await Axios.get(`${appConfig.SERVER_IP}/reservation/court/${courtId}/${reservationDate}`, {});
};

/**
 * 6. 사용가능 쿠폰 조회
 * @returns data
 * list : Array
 ** id : Number
 ** expirationDate : String<DateTime>
 ** coupon : Dict
 *** id : Number
 *** name : String
 *** type : String
 *** value : Number
 */
const getMemberCoupon = async (params, data) => {
  return await Axios.get(`${appConfig.SERVER_IP}/memberCoupon/`, {});
};

/**
 * 7. 결제 완료 시 주문 내역, 예약내역(까지완료), 결제 내역저장
 * @body
 * memberId : Number
 * reservation : Array
 ** courtId : Number
 ** startTime : String<DateTime>
 ** endTime : String<DateTime>
 * memberCouponId : Number
 * payment : Dict
 ** totalPrice : Number
 ** discountPrice : Number
 ** type : String
 * @returns data
 */
const postOrder = async (data) => {
  return await Axios.post(`${appConfig.SERVER_IP}/order`, data);
};

/**
 * 8. 마이페이지 주문 정보 조회(주문 일시 내림차순, 예약 시간 기준 내림차순)
 * @returns data
 * list : Array
 ** id : Number
 ** memberId : Number
 ** status : String
 ** createdAt : String<DateTime>
 ** resrvations : Array
 *** locations : Dict
 *** court : Dict
 *** reservations : Array
 **** id
 **** courtId
 **** startTime
 **** endTime
 */
const getOrderReservation = async (params, data) => {
  return await Axios.get(`${appConfig.SERVER_IP}/order/reservation`, {});
};

/**
 * 9. 계정 조회
 * @returns data
 * id : Number
 * phoneNumber : String
 * nickname : String
 * gender : String
 * smsAlarmYn : String
 * lastLoginDate : Date
 */
const getMember = async () => {
  return await Axios.get(`${appConfig.SERVER_IP}/member`, {});
};

/**
 * 10. 결제취소
 * @returns data
 * success : Boolean
 * orderId : String
 * reason : String
 */
const postOrderCancel = async (data) => {
  return await Axios.post(`${appConfig.SERVER_IP}/order/cancel`, data);
};

/**
 * 11. 프로모션 코드 유효성 검증(회원가입)
 * @returns data
 * success : Boolean
 * code : Number
 * msg : String
 */
const getPromotionValidate = async (data) => {
  return await Axios.get(`${appConfig.SERVER_IP}/promotion/validate/${data.promotionCode}`, {});
};

/**
 * 12. 프로모션 코드 발급(마이페이지)
 * @returns data
 * success : Boolean
 * code : Number
 * msg : String
 */
const postPromotion = async (data) => {
  return await Axios.post(`${appConfig.SERVER_IP}/promotion`, data);
};

/**
 * 99. 사후검증
 * @returns data
 * success : Boolean
 * code : String
 * msg : String
 */
const postOrderStatus = async (data) => {
  return await Axios.post(`${appConfig.SERVER_IP}/order/status`, data);
};

export {
  postSignUp,
  postIssueToken,
  getLocation,
  getCourtLocationid,
  getCourtLocationidDate,
  getReservationbyCourtIdAndDate,
  getMemberCoupon,
  postOrder,
  getOrderReservation,
  getMember,
  postOrderCancel,
  postOrderStatus,
  getPromotionValidate,
  postPromotion,
};
