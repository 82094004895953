class Reserve {
  id;
  courtId;
  startTime;
  endTime;
  constructor(data) {
    this.id = data.id;
    this.startTime = data.startTime;
    this.endTime = data.endTime;
  }
}

export default Reserve;
