import HomeLogo from "../../src/assets/img/home_logo_ex.png";
import "../assets/css/Mypage.css";
import { getMember, postPromotion, getMemberCoupon } from "../utils/appUtil";
import { useState, useEffect } from "react";
import MemberDTO from "../components/model/response/MemberDTO";
import CommonDTO from "../components/model/response/CommonDTO";
import { useQuery } from "@tanstack/react-query";
import PromotionInput from "../components/Input/PromotionInput";
import CouponBox from "../components/CouponBox";

const Mypage = () => {
  const [memberInfo, setMemberInfo] = useState(new MemberDTO({}));
  const [pcode, setPcode] = useState("");

  const getMemberAPI = useQuery({
    queryKey: ["getMemberAPI"],
    queryFn: () => getMember(),
  });

  const { refetch: refetchCoupons } = useQuery({
    queryKey: ["getMemberCouponAPI"],
    queryFn: () => getMemberCoupon(),
    enabled: false, // 처음에 자동 실행되지 않도록 설정
  });

  useEffect(() => {
    if (getMemberAPI.isSuccess && getMemberAPI.data) {
      setMemberInfo(getMemberAPI.data.data.data);
    }
  }, [getMemberAPI.isSuccess, getMemberAPI.data]);

  const handlePromotionCode = async () => {
    try {
      const result = await postPromotion({
        promotionCode: pcode,
      });
      let c_data = new CommonDTO(result.data);

      if (c_data.code === 200) {
        alert("프로모션 코드가 등록되었습니다.");
        await refetchCoupons(); // getMemberCouponAPI 재실행
      } else {
        alert(c_data.msg);
      }
      setPcode("");
    } catch (error) {
      alert("알 수 없는 오류 발생");
    }
  };

  return (
    <>
      <div className="common-content">
        <img className="home-logo" src={HomeLogo} alt="homelogo"></img>
      </div>
      <div className="page-content">
        <div className="sub-infor">
          <span>
            <strong>{memberInfo.nickname}</strong>
            님, 안녕하세요
          </span>
        </div>
        <div className="sub-infor">
          <span className="sub-title">쿠폰함</span>
          <CouponBox></CouponBox>
        </div>

        <div className="sub-infor">
          <span className="sub-title">프로모션 코드 등록</span>
          <p className="sub-desc">쿠폰으로 교환할 수 있는 코드입니다.</p>
          <PromotionInput pcode={pcode} setPcode={setPcode}></PromotionInput>
          <button
            className="promotion-btn"
            disabled={!pcode.length}
            onClick={(e) => {
              handlePromotionCode(e);
            }}
          >
            등록하기
          </button>
        </div>
      </div>
    </>
  );
};

export default Mypage;
