import { BorderRight } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { useState } from "react";

const CssTextField = styled(TextField)({
  "&": {
    margin: "10px 0px",
    width: "100%",
  },
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "0",
    "& input": {
      padding: "12px 0",
      fontSize: "0.9rem",
    },
    "& fieldset": {
      borderBottom: "1px solid rgba(0,0,0,0.3)",
      borderTop: "none",
      borderRight: "none",
      borderLeft: "none",
    },
    "&:hover fieldset": {
      borderBottomColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderBottomColor: "#6F7E8C",
    },
  },
});

const PromotionInput = (props) => {
  const { pcode, setPcode } = props;

  const handlePromotionCode = (e) => {
    let inputValue = e.target.value;
    // 대문자만
    inputValue = inputValue.toUpperCase();
    // 18자리 제한
    inputValue = inputValue.slice(0, 18);

    setPcode(inputValue);
  };

  return (
    <>
      <CssTextField
        type="text"
        id="custom-cs"
        value={pcode}
        placeholder="발급받은 프로모션 코드를 입력해 주세요"
        onChange={(e) => {
          handlePromotionCode(e);
        }}
      />
    </>
  );
};

export default PromotionInput;
