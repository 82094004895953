import "../assets/css/CouponBox.css";
import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { getMemberCoupon } from "../utils/appUtil";
import MemberCouponDTO from "./model/response/MemberCouponDTO";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";

const CouponBox = (props) => {
  const [coupons, setCoupons] = useState([]);
  const { selectVer, setPCoupon } = props;
  const [pId, setPId] = useState(null);

  const getMemberCouponAPI = useQuery({
    queryKey: ["getMemberCouponAPI"],
    queryFn: () => getMemberCoupon(),
  });

  useEffect(() => {
    if (getMemberCouponAPI.isSuccess && getMemberCouponAPI.data) {
      const couponList = getMemberCouponAPI.data.data.list.map((couponData) => new MemberCouponDTO(couponData));
      setCoupons(couponList);
    }
  }, [getMemberCouponAPI.isSuccess, getMemberCouponAPI.data]);

  const handleCoupon = (e, id) => {
    if (selectVer) {
      if (pId === id) {
        setPId(null); // 선택 취소
        setPCoupon(null); // 쿠폰 정보 초기화
      } else {
        const selectedCoupon = coupons.find((coupon) => coupon.id === id);
        setPId(id); // 선택
        setPCoupon(selectedCoupon); // 선택된 쿠폰 정보 설정
      }
    }
  };

  return (
    <div className="coupon-component">
      <div className="coupon-title-box">
        <div className="icon-box">
          <ConfirmationNumberOutlinedIcon fontSize="small" />
          <p className="valuable">사용가능 쿠폰</p>
        </div>
        <p className="count">{coupons.length}장</p>
      </div>
      <div className="coupon-box">
        {coupons.length > 0 ? (
          coupons.map((coupon) => (
            <div
              className={`coupon ${pId === coupon.id ? "picked" : ""}`}
              key={coupon.id}
              onClick={(e) => {
                handleCoupon(e, coupon.id);
              }}
            >
              <div className="coupon-infor">
                <p className="name">{coupon.coupon.name}</p>
                {coupon.coupon.type === "percent" ? (
                  <p className="value">{coupon.coupon.value.toLocaleString()}%</p>
                ) : (
                  <p className="value">{coupon.coupon.value.toLocaleString()}원</p>
                )}
                <p className="expirationDate">~ {coupon.expirationDate}</p>
              </div>
              <div className="coupon-right" />
            </div>
          ))
        ) : (
          <p>현재 사용 가능한 쿠폰이 없습니다.</p>
        )}
      </div>
    </div>
  );
};

export default CouponBox;
