import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useState, useEffect } from "react";
import { getLocation } from "../../utils/appUtil";
import { useQuery } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import Location from "../../components/model/Location";
import PopupFail from "../Feedback/PopupFail";

const CssSelectField = styled(Select)({
  "&": {
    height: "40px",
    width: "100%",
  },
});

const CssItemField = styled(MenuItem)({
  "&": {
    fontSize: "0.9rem",
    height: "32px",
    width: "100%",
  },
});

const StadiumInput = (props) => {
  //useState
  let { location, setLocation } = props;

  let [locations, setLocations] = useState([]);
  let [E_open, setE_Open] = useState(false);
  const [cookies, ,] = useCookies(["accessToken"]);

  //useQuery
  const getLocationAPI = useQuery({
    queryKey: ["getLocationAPI"],
    queryFn: () => getLocation(),
    enabled: false,
  });

  //useEffect
  useEffect(() => {
    getLocationAPI
      .refetch()
      .then((data) => {
        if (data.data.status === 200) {
          let result__ = data.data.data.list.map((court) => new Location(court));
          setLocations(result__);
          if (result__.length > 0) {
            setLocation(result__[0].id);
          }
        }
      })
      .catch((error) => {
        setE_Open(true);
        console.log(error);
      });
  }, []);

  const handleStadiumChange = (e) => {
    setLocation(e.target.value);
  };

  return (
    <>
      <CssSelectField
        labelId="stadium-select-label"
        id="stadium-select"
        displayEmpty
        value={location}
        onChange={handleStadiumChange}
      >
        <CssItemField disabled value="">
          <em>예약할 지점을 선택해 주세요</em>
        </CssItemField>
        {getLocationAPI.isSuccess
          ? locations.map((location) => (
              <CssItemField key={location.name} value={location.id}>
                {location.name}
              </CssItemField>
            ))
          : null}
      </CssSelectField>
      <PopupFail open={E_open} setOpen={setE_Open} msg={"오류 발생"} title={"매장을 불러올 수 없습니다."}></PopupFail>
    </>
  );
};

export default StadiumInput;
