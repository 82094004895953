import HomeLogo from "../../src/assets/img/home_logo_ex.png";
import "../assets/css/Confirm.css";
import { getOrderReservation, getMember } from "../utils/appUtil";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useState, useEffect } from "react";
import InformationDTO from "../components/model/response/InformationDTO";
import { postOrderCancel } from "../utils/appUtil";
import ProgressModal from "../components/modal/ProgressModal";
import NaverReserveLogo from "../../src/assets/img/naver_reserve_logo.png";

const Confirm = () => {
  //useState
  const [reservations, setReservations] = useState([]);
  const [userInfo, setUserInfo] = useState();
  const [open, setOpen] = useState(false);

  //useQuery
  const getMemberAPI = useQuery({
    queryKey: ["getMemberAPI"],
    queryFn: () => getMember(),
    enabled: false,
  });

  const getOrderReservationAPI = useQuery({
    queryKey: ["getOrderReservationAPI"],
    queryFn: () => getOrderReservation(),
    // enabled: false,
  });

  const postOrderCancelAPI = useMutation({
    mutationFn: (__data) => postOrderCancel(__data),
    onSuccess: (data) => {
      console.log(data);
      getOrderReservationAPI
        .refetch()
        .then((data) => {
          console.log(data);
          if (data.data.data.code === 200) {
            console.log(data.data.data.list);
            let result__ = data.data.data.list.map((information) => new InformationDTO(information));
            setReservations(result__);
            alert("취소가 완료되었습니다.");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSettled: () => {
      setOpen(false);
    },
  });

  //useEffect
  useEffect(() => {
    getMemberAPI
      .refetch()
      .then((data) => {
        if (data.data.data.code === 200) {
          setUserInfo(data.data.data.data);
          console.log(userInfo);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getOrderReservationAPI
      .refetch()
      .then((data) => {
        if (data.data.data.code === 200) {
          console.log(data.data.data.list);
          let result__ = data.data.data.list.map((information) => new InformationDTO(information));
          setReservations(result__);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleCancelbtn = async (e, id) => {
    //취소 progressBar
    setOpen(true);
    const __data = {
      orderId: id,
      reason: "사용자 예약 취소",
    };

    postOrderCancelAPI.mutate(__data);
  };

  function formatDate(timeString, noweek) {
    const date = new Date(timeString);

    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      weekday: "short",
    };

    if (noweek) {
      delete options.weekday;
    }

    const formattedDate = new Intl.DateTimeFormat("ko-KR", options).format(date);

    // 날짜 포맷에서 불필요한 공백 제거 및 조정
    return formattedDate.replace(/\s/g, "");
  }

  const formatTime = (dateString) => {
    const options = { hour: "2-digit", minute: "2-digit", hour12: false };
    return new Intl.DateTimeFormat("ko-KR", options).format(new Date(dateString));
  };

  const formatStatus = (status) => {
    if (status === "completed") {
      return "예약완료";
    } else if (status === "cancelled") {
      return "환불완료";
    } else {
      return status;
    }
  };

  return (
    <>
      <div className="common-content">
        <img className="home-logo" src={HomeLogo} alt="homelogo"></img>
      </div>
      <div className="confirm-title">
        <h3>예약 조회</h3>
        <p>내가 예약한 내역을 조회할 수 있어요</p>
      </div>
      <div className="confirm-content">
        <div>
          {reservations.map((reservation) => (
            <div className="confirm-box" key={reservation.id}>
              <div className="row-box">
                <div className="column-box">
                  <h4 className="reserveDate">{formatDate(reservation.createdAt)}</h4>
                  <p>예약번호 : {reservation.id}</p>
                </div>
                {reservation.naver === true ? (
                  <div className="img_box">
                    <img className="naver_logo" src={NaverReserveLogo}></img>
                  </div>
                ) : null}
                {reservation.status === "cancelled" ? (
                  <p className="refund">환불완료</p>
                ) : new Date(reservation.reservations[0].endTime) > new Date() ? (
                  <button
                    className="cancel_btn"
                    onClick={(e) => {
                      handleCancelbtn(e, reservation.id);
                    }}
                  >
                    예약취소
                  </button>
                ) : (
                  <p>이용완료</p>
                )}
              </div>
              <div className="confirm-detail-box">
                <p>지점 : {reservation.location.name}</p>
                <p>코트 : {reservation.court.name}</p>
                <p>
                  날짜 :{" "}
                  {formatDate(reservation.reservations[0].startTime) +
                    " " +
                    formatTime(reservation.reservations[0].startTime) +
                    " - " +
                    formatDate(reservation.reservations[0].endTime) +
                    " " +
                    formatTime(reservation.reservations[0].endTime)}
                </p>
                <p>상태 : {formatStatus(reservation.status)}</p>
                {reservation.naver === true ? <p>예약 by : {"네이버"}</p> : <p>예약 by : {"스핀업"}</p>}
              </div>
            </div>
          ))}
        </div>
        <ProgressModal open={open} setOpen={setOpen}></ProgressModal>
      </div>
    </>
  );
};

export default Confirm;
