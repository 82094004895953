import { styled } from "@mui/material/styles";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import ko from "dayjs/locale/ko";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from "react";

const CssDatePickerField = styled(DatePicker)({
  "&": {
    width: "100%",
  },
  "& .MuiInputBase-root": {
    height: "40px",
    overflow: "hidden",
  },
});

const DateInput = (props) => {
  //useState
  const { pdate, setPdate } = props;

  return (
    <>
      <LocalizationProvider
        styled={""}
        dateAdapter={AdapterDayjs}
        adapterLocale="ko"
        localeText={{
          cancelButtonLabel: "취소", // 변경할 취소 버튼 텍스트
          okButtonLabel: "확인", // 변경할 확인 버튼 텍스트
          datePickerToolbarTitle: "날짜 선택", // 변경할 툴바 타이틀
        }}
      >
        <CssDatePickerField
          format="YYYY년 MM월 DD일"
          value={pdate}
          disablePast
          maxDate={pdate.add("14", "day")}
          onChange={(e) => {
            setPdate(e);
          }}
        />
      </LocalizationProvider>
    </>
  );
};

export default DateInput;
