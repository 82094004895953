class OrderDTO {
  memberId;
  reservations;
  memberCouponId;
  payment;
  constructor(data) {
    this.memberId = data.memberId;
    this.reservations = data.reservations;
    this.payment = data.payment;
    if (data.memberCouponId !== null) {
      this.memberCouponId = data.memberCouponId;
    }
  }
}

export default OrderDTO;
