class PricePoliciesObj {
  from;
  to;
  price;
  constructor(data) {
    this.from = data.from;
    this.to = data.to;
    this.price = data.price;
  }
}

export default PricePoliciesObj;
