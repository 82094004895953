import { Routes, Route } from "react-router-dom";
import { Suspense, lazy } from "react";
import { useCookies } from "react-cookie";
import Loading from "../pages/Loading";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Register from "../pages/Register";
import Reservation from "../pages/Reservation";
import Mypage from "../pages/MyPage";
import Finish from "../pages/Reservation/Finish";
import VerifyReservation from "../pages/Reservation/VerifyReservation";
import Confirm from "../pages/Confirm";

const AppRouter = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/*" element={<ProtectedRoutes />} />
      </Routes>
    </Suspense>
  );
};

const ProtectedRoutes = () => {
  const [cookies] = useCookies(["accessToken"]);
  const accessToken = cookies.accessToken;

  if (!accessToken) {
    return <Login />;
  }

  return (
    <Routes>
      <Route path="/mypage" element={<Mypage />}></Route>
      <Route path="/reservation" element={<Reservation />}></Route>
      <Route path="/reservation/finish" element={<Finish />}></Route>
      <Route path="/reservation/verify" element={<VerifyReservation />}></Route>
      <Route path="/confirm" element={<Confirm />}></Route>
    </Routes>
  );
};

export default AppRouter;
