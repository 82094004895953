import { useNavigate } from "react-router-dom";
import "../assets/css/Header.css";
import { setNavId } from "../utils/store";
import { useDispatch } from "react-redux";
import MainLogo from "../../src/assets/img/header_icon_ex.png";
import { useQuery } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import { getMember } from "../utils/appUtil";
import MemberDTO from "./model/response/MemberDTO";
import { useState, useEffect } from "react";

const Header = () => {
  const navigate = useNavigate();
  const [cookies, , removeCookie] = useCookies(["accessToken"]);
  const [memberInfo, setMemberInfo] = useState(new MemberDTO({}));

  const getMemberAPI = useQuery({
    queryKey: ["getMemberAPI"],
    queryFn: () => getMember(),
    enabled: !!cookies.accessToken,
  });

  useEffect(() => {
    if (getMemberAPI.isSuccess && getMemberAPI.data) {
      setMemberInfo(getMemberAPI.data.data.data);
    }
  }, [getMemberAPI.isSuccess, getMemberAPI.data]);

  const LogOut = () => {
    removeCookie("accessToken");
    window.location.href = "/";
  };

  return (
    <header className="header-content">
      <img
        className="header-logo"
        src={MainLogo}
        alt="mainlogo"
        onClick={() => {
          navigate("/");
        }}
      ></img>
      <div className="member-infor">
        {getMemberAPI.isSuccess ? (
          <>
            <div className="nickname">
              <span>
                {`${String(memberInfo.nickname)} `}
                <span className="Nim">님</span>
              </span>
            </div>
            <button className="logout-btn" onClick={() => LogOut()}>
              로그아웃
            </button>
          </>
        ) : null}
      </div>
    </header>
  );
};

export default Header;
