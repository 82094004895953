import HomeLogo from "../../src/assets/img/home_logo_ex.png";
import "../assets/css/Login.css";
import PhoneInput from "../../src/components/Input/PhoneInput";
import PasswordInput from "../../src/components/Input/PasswordInput";
import NickNameInput from "../components/Input/NickNameInput";
import PromotionByRegisterInput from "../components/Input/PromotionByRegisterInput";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { postSignUp, getPromotionValidate } from "../utils/appUtil";
import TermsCheckbox from "../../src/components/Input/TermsCheckbox";
import PopupSuccess from "../components/Feedback/PopupSuccess";
import PopupMsg from "../components/Feedback/PopupFail";
import SuccessAnimation from "../components/Animation/SuccessAnimation";
import { initIamport, requestDanalCertification } from "../utils/Iamport";
import CommonDTO from "../components/model/response/CommonDTO";

const Register = () => {
  //useNavigate
  const navigate = useNavigate();

  //usestate
  let [phone, setPhone] = useState("");
  let [valPhone, setValPhone] = useState(false);
  let [PhonePopUpOpen, setPhonePopUpOpen] = useState(false);
  let [valCertificate, setValCertificate] = useState(false); //본인확인
  let [nickname, setNickname] = useState("");
  let [valNickname, setValNickname] = useState(false);
  let [password, setPassword] = useState(""); // 비밀번호
  let [valPassword, setValPassword] = useState(false); // 비밀번호 검증
  let [r_password, setr_Password] = useState(""); // 비밀번호 확인 검증
  let [valR_password, setValR_password] = useState(false); //비밀번호 확인 검증
  let [pcode, setPcode] = useState(""); //프로모션 코드
  let [valPcode, setValPcode] = useState(false); //프로모션 코드 검증
  let [PcodePopUpOpen, setPcodePopUpOpen] = useState(false); // 프로모션 팝업
  let [smsAlarmYn, setSmsAlarmYn] = useState(false);
  let [valTerms, setValTerms] = useState(false);
  let [isCanRegister, setIsCanRegister] = useState(false); //검증항목
  let [registerOpen, setRegisterOpen] = useState(false);
  let [registerMsg, setRegisterMsg] = useState("");
  let [registerTitle, setRegisterTitle] = useState("");
  let [Done, setDone] = useState(false);
  let [isLoading, setIsLoading] = useState(false);

  //useEffect

  useEffect(() => {
    initIamport();
  }, []);

  useEffect(() => {
    //프로모션 코드 여부에 따라 분기
    if (pcode.length === 0) {
      if (valCertificate && valNickname && valPassword && valR_password && valTerms) {
        setIsCanRegister(true);
      } else {
        setIsCanRegister(false);
      }
    } else {
      if (valCertificate && valNickname && valPassword && valR_password && valTerms && valPcode) {
        setIsCanRegister(true);
      } else {
        setIsCanRegister(false);
      }
    }
  }, [valCertificate, valNickname, valPassword, valR_password, valTerms, valPcode, pcode]);

  const handleRegisterBtn = async () => {
    setIsLoading(true);

    const __data = {
      phoneNumber: phone,
      password: password,
      nickName: nickname,
      smsAlarmYn: smsAlarmYn,
    };

    // valCode가 true인 경우에만 promotionCode 추가
    if (valPcode === true) {
      __data.promotionCode = pcode;
    }

    try {
      const result = await postSignUp(__data);
      console.log(result);
      if (result.status === 200) {
        const { code, data, msg, success } = result.data;
        if (code === 200) {
          const { id, nickname } = data;
          setNickname(nickname);
          setDone(true);
        } else if (code === 400) {
          setRegisterTitle("회원가입 실패");
          setRegisterMsg(
            `이미 가입된 계정입니다.
             오류가 지속되면 관리자에게 문의해 주세요.`
          );
          setRegisterOpen(true);
          setIsLoading(false);
        }
      }
    } catch (error) {
      setRegisterTitle("회원가입 실패");
      setRegisterMsg(`회원가입에 문제가 발생했습니다. 관리자에게 문의해 주세요.`);
      setRegisterOpen(true);
      setIsLoading(false);
      console.log(error);
    }
  };

  /**
   * 본인인증
   */

  const getCertification = async (e) => {
    const res = await requestDanalCertification({ phone: phone, merchant_uid: "222" });
    const { error_code, error_msg, imp_uid, merchant_uid, pg_provider, pg_type, success } = res;
    if (res.success) {
      //본인인증 성공 시,
      setPhonePopUpOpen(true);
      setValCertificate(true);
    } else {
      alert(error_msg);
    }
  };

  /**
   * 프로모션 검증
   */

  const getCertificatePcode = async (e) => {
    try {
      const result = await getPromotionValidate({ promotionCode: pcode });
      let c_data = new CommonDTO(result.data);
      if (c_data.code === 200) {
        setPcodePopUpOpen(true);
        setValPcode(true);
      } else {
        alert(c_data.msg);
      }
    } catch (error) {
      alert("알 수 없는 오류 발생");
    }
  };

  const activeEnter = (e) => {
    if (isCanRegister && e.key === "Enter") {
      handleRegisterBtn();
    }
  };

  return (
    <>
      <div className="common-content">
        <img className="home-logo" src={HomeLogo} alt="homelogo"></img>
      </div>
      {!Done ? (
        <>
          <div className="login-content">
            <div className="form-content">
              <div className="form-sub">
                <h3>휴대폰 번호</h3>
                {!valPhone ? (
                  <p className={!valPhone ? "error" : "success"}>휴대폰 번호는 11글자에요</p>
                ) : !valCertificate ? (
                  <p className="error">본인인증을 진행해 주세요!</p>
                ) : (
                  <p className="success">본인인증 완료</p>
                )}
                <PhoneInput
                  disabled={valCertificate}
                  phone={phone}
                  setPhone={setPhone}
                  valPhone={!valPhone}
                  setValPhone={setValPhone}
                  activeEnter={activeEnter}
                ></PhoneInput>
                <button
                  disabled={!valPhone || valCertificate}
                  className="certification-btn"
                  onClick={(e) => getCertification(e)}
                >
                  본인인증
                </button>
              </div>
              <div className="form-sub">
                <h3>닉네임</h3>
                <p className={!valNickname ? "error" : "success"}>닉네임은 2~8글자, 한글,영어,숫자로 설정해 주세요</p>
                <NickNameInput
                  nickname={nickname}
                  setNickname={setNickname}
                  valNickname={valNickname}
                  setValNickname={setValNickname}
                ></NickNameInput>
              </div>
              <div className="form-sub">
                <h3>비밀번호</h3>
                <p className={!valPassword ? "error" : "success"}>
                  비밀번호는 영문/숫자/특수문자 조합 8~20자리로 구성해 주세요
                </p>
                <PasswordInput
                  password={password}
                  setPassword={setPassword}
                  valPassword={valPassword}
                  setValPassword={setValPassword}
                ></PasswordInput>
              </div>
              <div className="form-sub">
                <h3>비밀번호 확인</h3>
                <p className={!valR_password ? "error" : "success"}>비밀번호를 재입력 해주세요</p>
                <PasswordInput
                  originPassword={password}
                  password={r_password}
                  setPassword={setr_Password}
                  valPassword={valR_password}
                  setValPassword={setValR_password}
                ></PasswordInput>
              </div>
              <div className="form-sub">
                <h3>프로모션 코드</h3>
                {pcode.length === 0 ? (
                  <p>쿠폰이 발급됩니다!</p>
                ) : (
                  <p className={!valPcode ? "error" : "success"}>코드 검증을 해주세요</p>
                )}
                <PromotionByRegisterInput
                  valPcode={valPcode}
                  pcode={pcode}
                  setPcode={setPcode}
                ></PromotionByRegisterInput>
                <button
                  disabled={!pcode || valPcode}
                  className="certification-btn promotion"
                  onClick={(e) => getCertificatePcode(e)}
                >
                  코드확인
                </button>
              </div>
              <div className="form-sub">
                <TermsCheckbox
                  smsAlarmYn={smsAlarmYn}
                  setSmsAlarmYn={setSmsAlarmYn}
                  valTerms={valTerms}
                  setValTerms={setValTerms}
                ></TermsCheckbox>
              </div>
            </div>
          </div>
          <button
            className="login-btn"
            disabled={isLoading || !isCanRegister}
            onClick={(e) => {
              handleRegisterBtn(e);
            }}
          >
            회원가입
          </button>
        </>
      ) : (
        <>
          <div className="login-content">
            <div className="done-content">
              <p className="nickname-infor">
                {`${nickname} `}
                <span className="msg">님! 반가워요</span>
              </p>
              <SuccessAnimation open={Done}></SuccessAnimation>
              <p>회원가입이 완료되었습니다!</p>
            </div>
            <button
              className="login-btn"
              onClick={() => {
                navigate("/");
              }}
            >
              홈으로
            </button>
          </div>
        </>
      )}

      {/* Popup Zone */}
      <PopupSuccess open={PhonePopUpOpen} setOpen={setPhonePopUpOpen} title="본인인증 성공"></PopupSuccess>
      <PopupSuccess open={PcodePopUpOpen} setOpen={setPcodePopUpOpen} title="코드확인 완료"></PopupSuccess>
      <PopupMsg open={registerOpen} setOpen={setRegisterOpen} msg={registerMsg} title={registerTitle}></PopupMsg>
    </>
  );
};

export default Register;
