import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { getMember } from "../utils/appUtil";
import MemberDTO from "../components/model/response/MemberDTO";
import { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import HomeLogo from "../../src/assets/img/home_logo_ex.png";
import "../assets/css/Home.css";
import SpinMap from "../components/SpinMap";

const Home = () => {
  const navigate = useNavigate();

  const [cookies, , removeCookie] = useCookies(["accessToken"]);
  const [memberInfo, setMemberInfo] = useState(new MemberDTO({}));

  const getMemberAPI = useQuery({
    queryKey: ["getMemberAPI"],
    queryFn: () => getMember(),
    enabled: !!cookies.accessToken,
  });

  useEffect(() => {
    if (getMemberAPI.isSuccess && getMemberAPI.data) {
      setMemberInfo(getMemberAPI.data.data.data);
    }
  }, [getMemberAPI.isSuccess, getMemberAPI.data]);

  console.log(memberInfo);

  return (
    <>
      <div className="cover-content">
        <img className="home-logo" src={HomeLogo} alt="homelogo"></img>
        {memberInfo.gender !== "machine" ? (
          <div className="btn-content">
            <button
              className="home-btn reserve-btn"
              onClick={() => {
                navigate("/reservation");
              }}
            >
              예약
            </button>
            <button
              className="home-btn select-btn"
              onClick={() => {
                navigate("/confirm");
              }}
            >
              조회
            </button>
          </div>
        ) : (
          // 키오스크용
          <div className="btn-content">
            <button
              className="home-btn reserve-btn"
              onClick={() => {
                navigate("/reservation");
              }}
            >
              예약
            </button>
          </div>
        )}
      </div>
      <div className="infor-content">
        <div className="infor-sub">
          <h2>소개</h2>
          <p>
            <strong>스핀업 24</strong>는 무인 테니스 트레이닝 공간입니다.
          </p>
          <p>테니스를 사랑하는 회원님들의 성장 기회를 제공합니다.</p>
        </div>
        <div className="infor-sub">
          <h2>매장 안내</h2>
          <p>지점: 서울 양천점</p>
          <p>주소: 서울 양천구 오목로 337-10 지하1층 스핀업24(101호)</p>
        </div>
        <SpinMap></SpinMap>
      </div>
    </>
  );
};

export default Home;
