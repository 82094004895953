import CouponObj from "../CouponObj";

class MemberDTO {
  id;
  phoneNumber;
  nickname;
  gender;
  smsAlarmYn;
  lastLoginDate;
  constructor(data) {
    this.id = data.id || null;
    this.phoneNumber = data.phoneNumber || null;
    this.nickname = data.nickname || null;
    this.gender = data.gender || null;
    this.smsAlarmYn = data.smsAlarmYn || null;
    this.lastLoginDate = data.lastLoginDate || null;
  }
}

export default MemberDTO;
