import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { forwardRef } from "react";

const CssDialogField = styled(Dialog)({
  "& .MuiPaper-root": {
    boxShadow: "0px 11px 15px -7px rgba(0,0,0,0.2);",
    "& h2": {
      fontWeight: "700",
      color: "#E55D53",
    },
  },
  "& .MuiDialogContent-root": {
    "& p": {},
  },
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PopupFail = (props) => {
  const { open, setOpen, title, msg } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CssDialogField
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {msg.split("\n").map((line, index) => (
            <>
              {line}
              <br key={index} />
            </>
          ))}
        </DialogContentText>
      </DialogContent>
    </CssDialogField>
  );
};

export default PopupFail;
