class ReservationDTO {
  courtId;
  startTime;
  endTime;
  constructor(data) {
    this.courtId = data.courtId;
    this.startTime = data.startTime;
    this.endTime = data.endTime;
  }
}

export default ReservationDTO;
