import logo from "./logo.svg";
import "./App.css";
import AppRouter from "./utils/Router";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Tabbar from "./components/Tabbar";

function App() {
  return (
    <>
      <div className="App">
        <Header></Header>
        <div className="view-root">
          <div className="view-content">
            <AppRouter></AppRouter>
          </div>
        </div>
        <Footer></Footer>
      </div>
      <Tabbar></Tabbar>
    </>
  );
}

export default App;
