import "../../assets/css/Input/ScheduleInput.css";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { getLocationbyLocationid } from "../../utils/appUtil";
import { useQuery } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import { getReservationbyCourtIdAndDate } from "../../utils/appUtil";
import Reserve from "../model/Reserve";

const ScheduleInput = (props) => {
  //useState
  const { totalPrice, setTotalPrice, court, pdate, sTime, setSTime, eTime, setETime, done, setDone } = props;

  //단위 설정 : 30분
  const TIME_UNIT = 30;
  const GROUP_UNIT = 4;
  const TIME_TITLES = { 0: "오전", 6: "오후" };
  //예약 허용 차이 시간(분)
  const EFFECT_TIMES = 0;
  //시간 배열
  let [reserveTimeList, SetReserveTimeList] = useState([]);
  let [picked, setPicked] = useState(false);
  let [schedules, setSchedules] = useState([]);
  let [group, setGroup] = useState([]);
  let [KRTime, setKRTime] = useState(dayjs("00:00", "HH:mm"));
  //예약 가능 시간 설정
  let [startTime, setStartTime] = useState(dayjs("00:00", "HH:mm"));
  let [endTime, setEndTime] = useState(dayjs("23:59", "HH:mm"));
  let [firstRegis, setFirstRegis] = useState(false);
  //선택 가격 정책별 배열
  let [priceSegments, setPriceSegments] = useState({});

  //useQuery
  const getReservationbyCourtIdAndDateAPI = useQuery({
    queryKey: ["getReservationbyCourtIdAndDateAPI"],
    queryFn: () =>
      getReservationbyCourtIdAndDate({
        courtId: court.id,
        reservationDate: pdate.format("YYYY-MM-DD"),
      }),
    enabled: false,
  });

  //useEffect
  useEffect(() => {
    setTimeSet();
    if (done) {
      /*
       * 분할 계산 추가
       */
      const nowTime = dayjs();

      if (sTime.isBefore(nowTime)) {
        setKRTime(getKRtime(nowTime, eTime));
      } else {
        setKRTime(getKRtime(sTime, eTime));
      }

      setTotalPrice(getTotalPrice());
    }
  }, [sTime, eTime]);

  //초기화
  useEffect(() => {
    setTimeSet();
    setSTime(dayjs().subtract(EFFECT_TIMES, "minutes")); //현재 시간(20분 패치)
    setETime(dayjs("2099-01-01", "YYYY-MM-DD"));
    setPicked(false);
    setDone(false);
    setStartTime(pdate.startOf("day"));
    setEndTime(pdate.endOf("day"));
  }, [pdate, court]);

  //useEffect
  useEffect(() => {
    if (court !== null) {
      getReservationbyCourtIdAndDateAPI
        .refetch()
        .then((data) => {
          if (data?.data?.data?.code === 200) {
            let result__ = data.data.data.list.map((reserve) => new Reserve(reserve));
            SetReserveTimeList(result__);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [court, pdate]);

  /**
   * 기본 시간 설정
   */
  const setTimeSet = () => {
    let __arr = [];

    while (startTime.isBefore(endTime)) {
      __arr.push(startTime);
      startTime = startTime.add(30, "minutes");
    }
    setSchedules(__arr);

    let __group = [];
    for (let i = 0; i < __arr.length; i += GROUP_UNIT) {
      const group = __arr.slice(i, i + GROUP_UNIT);
      __group.push(group);
    }
    setGroup(__group);
  };

  /**
   * 클릭 시, 시간 설정
   */
  const handleTimeSet = (e, sc) => {
    if (!picked) {
      setSTime(sc);
      setPicked(true);
      setDone(false);
    } else {
      e.currentTarget.classList.add("ended");
      setETime(sc);
      setPicked(false);
      setDone(true);
    }
  };

  /**
   * 가능 시간 설정
   */

  const isSCIncluded = (sc) => {
    return reserveTimeList.some((reserve) => {
      const sc__ = dayjs(reserve.startTime);
      if (sc__.hour() === sc.hour() && sc__.minute() === sc.minute()) return true;
      return false;
    });
  };

  /**
   * 시간 -> KR 표기법
   */

  const getKRtime = (sTime, eTime) => {
    // 종료 시간에 30분 추가
    const adjustedEndTime = eTime.add(30, "minute");

    // 전체 시간 차이를 시간으로 계산
    const totalHours = adjustedEndTime.diff(sTime, "hour");
    // 전체 시간 차이에서 분으로 계산한 후, 이미 계산된 시간(시간 x 60)을 제외한 나머지 분 계산
    const remainingMinutes = adjustedEndTime.diff(sTime, "minute") % 60;

    let resultStr = "";
    if (totalHours > 0) {
      resultStr += totalHours + "시간";
    }
    if (remainingMinutes > 0) {
      resultStr += remainingMinutes + "분";
    }

    return resultStr;
  };

  /**
   * 시간별 가격 계산 함수
   */
  const getTotalPrice = () => {
    /*
     * 분할 계산 추가
     */
    const nowTime = dayjs();

    let minutes;
    if (sTime.isBefore(nowTime)) {
      alert("선택한 시간은 현재 시간보다 늦어, 할인된 금액이 적용됩니다.");
      minutes = eTime.add(30, "minute").diff(nowTime, "minute");
    } else {
      minutes = eTime.add(30, "minute").diff(sTime, "minute");
    }

    const startTime = sTime.isBefore(nowTime) ? nowTime : sTime;
    const endTime = eTime.add(30, "minute");

    let totalPrice = 0;
    let segment = {};

    // 시간 단위별로 가격을 계산
    let currentTime = startTime.clone();
    while (currentTime.isBefore(endTime)) {
      const nextTime = currentTime.add(30, "minute");
      const segmentEndTime = nextTime.isBefore(endTime) ? nextTime : endTime;
      const segmentMinutes = segmentEndTime.diff(currentTime, "minute");
      const segmentPrice = getPriceForTime(currentTime);

      totalPrice += segmentPrice * (segmentMinutes / TIME_UNIT);

      // segment 객체에 가격별로 분 단위 저장
      if (segment[segmentPrice]) {
        segment[segmentPrice] += segmentMinutes;
      } else {
        segment[segmentPrice] = segmentMinutes;
      }

      currentTime = segmentEndTime;
    }

    setPriceSegments(segment);

    return Math.floor(totalPrice); // 소수점 자리를 버림
  };

  /**
   * 시간별 가격 계산 함수
   */
  const getPriceForTime = (time) => {
    const timeStr = time.format("HH:mm:ss");
    for (const policy of court.pricePolicies) {
      if (timeStr >= policy.from && timeStr <= policy.to) {
        return policy.price;
      }
    }
    return court.price;
  };

  /**
   * 렌더링 초기에 초기화
   * 첫 isSCIncluded 탐색 변수
   */
  let isDisabledAfterPicked = false;

  return (
    <>
      <div className="schedule-content">
        {group.map((scs, idx) => {
          const isTimeTitle = Object.keys(TIME_TITLES).includes(String(idx));

          return (
            <>
              {isTimeTitle && (
                <div className="row-box" key={TIME_TITLES[idx]}>
                  <span className="time-title">{TIME_TITLES[idx]}</span>
                </div>
              )}
              <div className="row-box" key={idx}>
                {scs.map((sc) => {
                  const isReserved = isSCIncluded(sc); // 예약된 시간인지

                  if (picked && isReserved && sc.isAfter(sTime)) {
                    isDisabledAfterPicked = true; // 예약이 시작되면 이후 시간을 모두 disable
                  }

                  return (
                    <div
                      className={`schedule-box 
                     ${isDisabledAfterPicked || isSCIncluded(sc) || sc.isBefore(sTime) ? "disabled" : ""} ${
                        done && sc.isBetween(sTime, eTime) ? "between" : ""
                      }
                     ${sc.isSame(sTime) ? "started" : ""}
                     ${done && sc.isSame(eTime) ? "ended" : ""}
                     ${court && `price-${getPriceForTime(sc)}`}`}
                      key={sc.format("HH:mm")}
                      onClick={
                        !isDisabledAfterPicked
                          ? (e) => {
                              if (
                                sc.isAfter(dayjs().subtract(EFFECT_TIMES, "minutes")) && // (전 20분까지는 예악 가능) 미래 시
                                !isSCIncluded(sc) && // 예약된 시간이 아님
                                !(picked && sc.isBefore(sTime)) // 시작 시간 선택 시 이전 시간 선택 불가
                              ) {
                                handleTimeSet(e, sc);
                              }
                            }
                          : null
                      }
                    >
                      <span>{sc.format("HH:mm")}</span>
                    </div>
                  );
                })}
              </div>
            </>
          );
        })}
      </div>
      <div className="schedule-infor">
        {court && court.pricePolicies
          ? court.pricePolicies.map((policy) => (
              <div className="infor-group" key={policy.price}>
                <div className={`color-box reserve price-${policy.price}`}></div>
                <span>{`${policy.price.toLocaleString()}원`}</span>
              </div>
            ))
          : null}
        <div className="infor-group">
          <div className="color-box cant"></div>
          <span>불가</span>
        </div>
      </div>
      <div className={`reserve-infor ${done ? "" : "disabled"}`}>
        <div className="row-div">
          <span className="pick_title">선택한 시간 </span>
          <span className="pick_time">
            {sTime.isBefore(dayjs()) ? dayjs().format("HH:mm") : sTime.format("HH:mm")} ~{" "}
            {eTime.add(30, "minutes").format("HH:mm")}
          </span>
        </div>
        {Object.keys(priceSegments).map((price) => {
          const minutes = priceSegments[price];
          const KRTime = `${minutes}분`;
          return (
            <span className="reserve-result" key={price}>
              {`${parseInt(price).toLocaleString()}원/30분 x (${KRTime}) = ${(
                price *
                (minutes / 30)
              ).toLocaleString()}원`}
            </span>
          );
        })}
        {/* 총합 */}
        <span className="reserve-result final">{`총 ${totalPrice.toLocaleString()}원`}</span>
      </div>
    </>
  );
};

export default ScheduleInput;
