import PricePoliciesObj from "../PricePoliciesObj";

class CourtDTO {
  id;
  name;
  description;
  price;
  status;
  pricePolicies;
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.price = data.price;
    this.status = data.status;
    if (data.pricePolicies) {
      this.pricePolicies = data.pricePolicies.map((obj) => new PricePoliciesObj(obj));
    }
  }
}

export default CourtDTO;
