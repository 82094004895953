class LocationObj {
  address;
  id;
  name;
  constructor(data) {
    this.address = data.address;
    this.id = data.id;
    this.name = data.name;
  }
}

export default LocationObj;
