import HomeLogo from "../../src/assets/img/home_logo_ex.png";
import "../assets/css/Loading.css";

const Loading = () => {
  return (
    <>
      <div className="common-content">
        <img className="home-logo" src={HomeLogo} alt="homelogo"></img>
      </div>
      <div className="loading-content">
        <span className="loading_a">
          SPINN<span className="period">UP</span>
        </span>
        <div className="sub-infor"></div>
      </div>
    </>
  );
};

export default Loading;
