class CourtObj {
  description;
  id;
  name;
  price;
  status;
  constructor(data) {
    this.description = data.description || null;
    this.id = data.id || null;
    this.name = data.name || null;
    this.price = data.price || null;
    this.status = data.status || null;
  }
}

export default CourtObj;
