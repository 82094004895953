const appConfig = {
  NAVER_CLIENT_ID: process.env.REACT_APP_NAVER_CLIENT_ID,
  NAVER_CLIENT_SECRET: process.env.REACT_APP_NAVER_CLIENT_SECRET,
  SERVER_IP: process.env.REACT_APP_SERVER_HOST,
  HTTP_ONLY: process.env.REACT_APP_HTTP_ONLY,
  IMP_CODE: process.env.REACT_APP_IMP_CODE,
  WEB_HOST: process.env.REACT_APP_WEB_HOST,
};

export default appConfig;
