class PaymentDTO {
  totalPrice;
  discountPrice;
  type;
  constructor(data) {
    this.totalPrice = data.totalPrice;
    this.discountPrice = data.discountPrice;
    this.type = data.type;
  }
}

export default PaymentDTO;
