import { useNavigate } from "react-router-dom";
import "../assets/css/Tabbar.css";
import { setNavId } from "../utils/store";
import { useDispatch } from "react-redux";
import MainLogo from "../../src/assets/img/header_icon_ex.png";
import { useQuery } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import { getMember } from "../utils/appUtil";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import SubjectIcon from "@mui/icons-material/Subject";
import ContactPageIcon from "@mui/icons-material/ContactPage";

const Tabbar = () => {
  const navigate = useNavigate();
  const [cookies, , removeCookie] = useCookies(["accessToken"]);

  return (
    <header className="tabbar-content">
      <div className="tabbar_box">
        <div className="link-component">
          <div
            className="link-box"
            onClick={() => {
              navigate("/reservation");
            }}
          >
            <div className="icon">
              <MoreTimeIcon></MoreTimeIcon>
            </div>
            <p className="icon_desc">예약</p>
          </div>
        </div>
        <div className="link-component">
          <div
            className="link-box"
            onClick={() => {
              navigate("/confirm");
            }}
          >
            <div className="icon">
              <SubjectIcon></SubjectIcon>
            </div>
            <p className="icon_desc">예약내역</p>
          </div>
        </div>
        <div className="link-component">
          <div
            className="link-box"
            onClick={() => {
              navigate("/mypage");
            }}
          >
            <div className="icon">
              <ContactPageIcon></ContactPageIcon>
            </div>
            <p className="icon_desc">마이페이지</p>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Tabbar;
