import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { forwardRef } from "react";
import "../../assets/css/modal/PaymentModal.css";
import Tosspayment_Logo from "../../assets/img/tosspayment.png";
import Naverpayment_Logo from "../../assets/img/naverpayment.png";
import KaKaopayment_Logo from "../../assets/img/kakaopayment.png";
import { requestTossPayment, requestKaKaoPayment, requestNaverPayment } from "../../utils/Iamport";
import { postOrder, postOrderStatus } from "../../utils/appUtil";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Progressbar from "../Feedback/Progressbar";
import OrderStatusDTO from "../model/request/OrderStatusDTO";

const CssDialogField = styled(Dialog)({
  "& .MuiPaper-root": {
    boxShadow: "0px 11px 15px -7px rgba(0,0,0,0.2);",
    "& h2": {
      fontWeight: "700",
    },
  },
  "& .MuiDialogContent-root": {
    "& .MuiTypography-root": {
      display: "flex",
      "@media (max-width: 500px)": {
        flexDirection: "column",
      },
    },
  },
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PaymentModal = (props) => {
  //useNavigate
  const navigate = useNavigate();
  //useState
  const { open, setOpen, totalPrice, member, orderDTO } = props;
  const [isProcessing, setIsProcessing] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  //useQuery
  const postOrderAPI = useQuery({
    queryKey: ["postOrderAPI", orderDTO],
    queryFn: () => postOrder(orderDTO),
    enabled: false,
  });

  const getMerchantUid = async () => {
    console.table("orderDTO : ", orderDTO);
    try {
      const res = await postOrderAPI.refetch();
      if (res.data?.data?.code === 200) {
        return res.data.data.data.merchantUid;
      } else {
        alert(res.data.data.msg || "문제가 발생하였습니다.");
        return null;
      }
    } catch (err) {
      console.error(err);
      alert("An error occurred while fetching merchantUid");
      return null;
    }
  };

  // const postHoc = async (merchantUid) => {
  //   const orderStatusDTO = new OrderStatusDTO({
  //     merchant_uid: "20240303205125053_165",
  //   });
  //   console.table("orderStatusDTO : ", orderStatusDTO);
  //   try {
  //     const res = await postOrderStatus(orderStatusDTO);
  //     console.log(res);
  //   } catch (err) {
  //     console.error(err);
  //     return null;
  //   }
  // };

  const handleTossPayment = async (e) => {
    if (isProcessing) return; // 이미 처리 중이면 함수 실행을 중단
    setIsProcessing(true); // 처리 시작을 표시

    const merchantUid = await getMerchantUid();

    if (!merchantUid) {
      setIsProcessing(false); // 처리 완료 후 처리 중 상태 해제
      return;
    }

    const { nickname, phoneNumber } = member;
    let pg_data = {
      merchant_uid: merchantUid,
      amount: Number(totalPrice),
      buyer_name: nickname,
      buyer_tel: phoneNumber,
    };

    const res = await requestTossPayment(pg_data, setIsProcessing);
    console.log(res);
    if (!res.hasOwnProperty("error_code")) {
      //사후검증필요
      // const respostHoc = await postHoc(merchantUid);
      navigate("/reservation/finish", { state: { orderDTO: orderDTO } });
    } else {
      alert("결제에 실패했습니다.");
    }
    setIsProcessing(false); // 처리 완료 후 처리 중 상태 해제
  };

  const handleKaKaoPayment = async (e) => {
    if (isProcessing) return; // 이미 처리 중이면 함수 실행을 중단
    setIsProcessing(true); // 처리 시작을 표시

    const merchantUid = await getMerchantUid();

    if (!merchantUid) {
      setIsProcessing(false); // 처리 완료 후 처리 중 상태 해제
      return;
    }
    const { nickname, phoneNumber } = member;
    let pg_data = {
      merchant_uid: merchantUid,
      amount: Number(totalPrice),
      buyer_name: nickname,
      buyer_tel: phoneNumber,
    };

    const res = await requestKaKaoPayment(pg_data, setIsProcessing);
    console.log(res);
    if (res.success) {
      //사후검증
      // const respostHoc = await postHoc(merchantUid);
      navigate("/reservation/finish", { state: { orderDTO: orderDTO } });
    } else {
      alert("결제에 실패했습니다.");
    }
    setIsProcessing(false); // 처리 완료 후 처리 중 상태 해제
  };

  const handleNaverPayment = async (e) => {
    if (isProcessing) return; // 이미 처리 중이면 함수 실행을 중단
    setIsProcessing(true); // 처리 시작을 표시

    const merchantUid = await getMerchantUid();

    const { nickname, phoneNumber } = member;
    let pg_data = {
      merchant_uid: merchantUid,
      amount: Number(totalPrice),
      buyer_name: nickname,
      buyer_tel: phoneNumber,
    };

    requestNaverPayment(pg_data, setIsProcessing);
  };

  return (
    <>
      <CssDialogField
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={(event, reason) => {
          if (!isProcessing) {
            handleClose();
          } else if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        {!isProcessing ? (
          <>
            <DialogTitle>결제 방법 선택</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <div
                  className="pay_btn"
                  onClick={(e) => {
                    handleTossPayment(e);
                  }}
                >
                  <img className="logo" src={Tosspayment_Logo} alt="토스페이먼츠" />
                  <p>카드 결제</p>
                </div>
                <div
                  className="pay_btn"
                  onClick={(e) => {
                    handleNaverPayment(e);
                  }}
                >
                  <img className="logo" src={Naverpayment_Logo} alt="네이버페이" />
                  <p>네이버 페이</p>
                </div>
                <div
                  className="pay_btn"
                  onClick={(e) => {
                    handleKaKaoPayment(e);
                  }}
                >
                  <img className="logo" src={KaKaopayment_Logo} alt="네이버페이" />
                  <p>카카오 페이</p>
                </div>
              </DialogContentText>
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle>결제 처리 중</DialogTitle>
            <DialogContent>
              <Progressbar />
            </DialogContent>
          </>
        )}
      </CssDialogField>
    </>
  );
};

export default PaymentModal;
