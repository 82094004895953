class CouponObj {
  id;
  name;
  type;
  value;
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.type = data.type;
    this.value = data.value;
  }
}

export default CouponObj;
