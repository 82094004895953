import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

const CssTextField = styled(TextField)({
  "&": {
    width: "100%",
  },
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C",
    },
  },
});

const PasswordInput = (props) => {
  const {
    isLogin,
    originPassword,
    password,
    setPassword,
    valPassword,
    setValPassword,
    activeEnter,
  } = props;

  const handlePassword = (e) => {
    let __password = e.target.value;

    //공백 금지
    const spaceRegex = /\s/;
    __password = __password.replace(spaceRegex, "");

    // 20자리 제한
    __password = __password.slice(0, 20);

    //로그인용일 경우,
    if (isLogin) {
      //4자리이상
      const passwordRegex = /^.{4,}$/;
      if (passwordRegex.test(__password)) {
        setValPassword(true);
      } else {
        setValPassword(false);
      }
    } else {
      //비밀번호 확인인 경우, 일치검증
      if (!!originPassword) {
        originPassword === __password
          ? setValPassword(true)
          : setValPassword(false);
      } else {
        //a-Z|숫자|특수문자포함|8자리이상
        const passwordRegex =
          /^(?=.*[a-z|A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

        if (passwordRegex.test(__password)) {
          setValPassword(true);
        } else {
          setValPassword(false);
        }
      }
    }

    setPassword(__password);
  };

  return (
    <>
      <CssTextField
        type="password"
        id="custom-cs"
        value={password}
        onChange={(e) => {
          handlePassword(e);
        }}
        onKeyDown={(e) => activeEnter && activeEnter(e)}
      />
    </>
  );
};

export default PasswordInput;
