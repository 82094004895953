import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { useState } from "react";

const CssTextField = styled(TextField)({
  "&": {
    width: "100%",
  },
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C",
    },
  },
});

const NickNameInput = (props) => {
  const {
    nickname,
    setNickname,
    valNickname,
    setValNickname,
    activeEnter,
    disabled,
  } = props;

  const handleNickname = (e) => {
    let inputValue = e.target.value;
    // 대문자만
    inputValue = inputValue.toUpperCase();
    // 10자리 제한
    inputValue = inputValue.slice(0, 10);

    // 한글, 영어, 숫자만 허용
    const nickname_reg = /^[가-힣|A-Z|0-9|]+$/;
    if (nickname_reg.test(inputValue)) {
      // 글자수 검증(2~8자리)
      if (inputValue.length >= 2 && inputValue.length <= 8) {
        setValNickname(true);
      } else {
        setValNickname(false);
      }
    } else {
      setValNickname(false);
    }

    setNickname(inputValue);
  };

  return (
    <>
      <CssTextField
        disabled={disabled}
        type="text"
        id="custom-cs"
        value={nickname}
        onChange={(e) => {
          handleNickname(e);
        }}
      />
    </>
  );
};

export default NickNameInput;
