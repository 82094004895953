import * as React from "react";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";

const CssDialogField = styled(LinearProgress)({
  "& .MuiLinearProgress-bar": {
    backgroundColor: "#1f4676",
  },
});

const Progressbar = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <CssDialogField />
    </Box>
  );
};

export default Progressbar;
