import appConfig from "../../../config/appConfig";

const { IMP_CODE } = appConfig;

class OrderStatusDTO {
  imp_uid;
  merchant_uid;
  constructor(data) {
    this.imp_uid = IMP_CODE;
    this.merchant_uid = data.merchant_uid;
  }
}

export default OrderStatusDTO;
