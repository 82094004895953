import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { useState } from "react";

const CssTextField = styled(TextField)({
  "&": {
    width: "100%",
  },
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C",
    },
  },
});

const PhoneInput = (props) => {
  let [display, setDisplay] = useState("010-");

  let { phone, setPhone, valPhone, setValPhone, activeEnter, disabled } = props;

  const handlePhone = (e) => {
    let inputValue = e.target.value;
    //backspace
    if (e.nativeEvent.inputType === "deleteContentBackward") {
      if (display.endsWith("-")) {
        // display의 마지막 글자가 "-"인 경우
        inputValue = inputValue.substring(0, inputValue.length - 1);
      }
    }

    // 입력값에서 숫자 이외의 문자를 제거
    const sanitizedValue = inputValue.replace(/[^0-9]/g, "");
    // 최대 11자리까지만 유지
    const formattedValue = sanitizedValue.slice(0, 11);
    setPhone(formattedValue);

    // Phone 유효성 검사
    if (formattedValue.length === 11) {
      setValPhone(true);
    } else {
      setValPhone(false);
    }

    // ###-####-#### 형식으로 표시
    let displayedValue = "";
    displayedValue += formattedValue.slice(0, 3);
    if (formattedValue.length >= 3) {
      displayedValue += "-";
    }
    displayedValue += formattedValue.slice(3, 7);
    if (formattedValue.length >= 7) {
      displayedValue += "-";
    }
    displayedValue += formattedValue.slice(7);

    setDisplay(displayedValue);
  };

  return (
    <>
      <CssTextField
        type="text"
        required
        placeholder="010-0000-0000"
        disabled={disabled}
        value={display}
        onChange={(e) => {
          handlePhone(e);
        }}
        onKeyDown={(e) => activeEnter(e)}
      />
    </>
  );
};

export default PhoneInput;
