import "../assets/css/Footer.css";

const Footer = () => {
  return (
    <footer className="footer-main">
      <div className="footer-content">
        <div className="sub-content">
          <p className="com_name">SPINNUP24</p>
        </div>
        <div className="sub-content">
          <div className="address">
            <p>
              <strong>스핀업24</strong>
            </p>
            <p>사업자등록번호 : 856-71-00612 | 대표 : 신환희</p>
            <p>주소 : 서울특별시 양천구 오목로 337-10 소망빌딩 지하1층 101호</p>
            <p>이메일 : contact@spinup24.com</p>
            <p>대표전화 : 02-3495-4665 </p>
          </div>
          <div className="address">
            <p>
              <strong>고객문의</strong>
            </p>
            <p>카카오톡 : @스핀업24</p>
          </div>
        </div>

        <div className="sub-content">
          <p className="link">이용약관</p>
          <p className="link">개인정보처리방침</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
