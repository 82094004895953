import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";

const CssFormControlLabel = styled(FormControlLabel)({
  "&": {
    width: "100%",
    height: "2rem",
  },
  "& span.MuiTypography-root": {
    fontSize: "12px",
  },
});

const TermsCheckbox = (props) => {
  const { smsAlarmYn, setSmsAlarmYn, valTerms, setValTerms } = props;

  //useState
  let [checked, setChecked] = useState([false, false]);

  const handleSmsAlarmYn = (e) => {
    setSmsAlarmYn(e.target.checked);
  };

  const handleChecked = (e, idx) => {
    let __checked = [...checked];
    __checked[idx] = e.target.checked;
    setChecked(__checked);

    //유효성 검사
    if (__checked.every((e) => e === true)) {
      setValTerms(true);
    } else {
      setValTerms(false);
    }
  };

  return (
    <FormGroup>
      <CssFormControlLabel
        required
        control={
          <Checkbox
            checked={checked[0]}
            onChange={(e) => {
              handleChecked(e, 0);
            }}
          />
        }
        label="[필수] 개인정보 수집 및 이용 동의"
      />
      <CssFormControlLabel
        required
        control={
          <Checkbox
            checked={checked[1]}
            onChange={(e) => {
              handleChecked(e, 1);
            }}
          />
        }
        label="[필수] 이용약관동의"
      />
      <CssFormControlLabel
        control={
          <Checkbox
            checked={smsAlarmYn}
            onChange={(e) => {
              handleSmsAlarmYn(e);
            }}
          />
        }
        label="[선택] SMS 수신 - 마켓팅 및 프로모션 동의"
      />
    </FormGroup>
  );
};

export default TermsCheckbox;
