import appConfig from "../config/appConfig";

const { IMP } = window;
const { IMP_CODE, WEB_HOST } = appConfig;

/**
 * 1. 가맹점 객체 초기화
 */
const initIamport = () => {
  IMP.init(IMP_CODE);
};

/**
 * 2. 결제 요청하기(토스)
 *
 * pay_method string
    카드 (card)
    계좌이체(trans)
    가상계좌(vbank)
    휴대폰 소액결제(phone)
    도서문화상품권(booknlife)
    스마트문상(smartculture)
    컬쳐랜드(cultureland)
    카카오페이 (kakaopay)
    네이버페이 (naverpay)
    엘페이 (lpay)
    삼성페이(samsung)
    SSGpay (ssgpay)
    애플페이 (applepay)
    페이코 (payco)
    토스간편결제 (tosspay)
*/
const requestTossPayment = (pg_data, setIsProcessing) => {
  const { merchant_uid, amount, buyer_name, buyer_tel } = pg_data;
  console.log(`${merchant_uid}`);
  return new Promise((resolve) => {
    IMP.request_pay(
      {
        pg: "tosspayments.im_spinerg532",
        merchant_uid: merchant_uid,
        name: "스핀업24",
        pay_method: "card",
        escrow: false,
        amount: amount,
        // tax_free: 3000,
        buyer_name: buyer_name,
        // buyer_email: "buyer@example.com",
        buyer_tel: buyer_tel,
        // buyer_addr: "성수이로 20길 16",
        // buyer_postcode: "04783",
        confirm_url: `${WEB_HOST}/spinup/order/confirm`,
        m_redirect_url: `${WEB_HOST}/reservation/verify`, // 모바일 환경에서 필수 입력
        // notice_url: "/notice",
        currency: "KRW",
        locale: "ko",
        // custom_data: { userId: 30930 },
        // display: { card_quota: [0, 6] }, // 할부
        // appCard: false, //앱카드 설정
        // useCardPoint: true, //카드 포인트 설정
        // bypass: {
        //   tosspayments: {
        //     useInternationalCardOnly: true, // 영어 결제창 활성화
        //   },
        // },
      },
      (response) => {
        resolve(response);
      }
    );
  });
};

/**
 * 3. 결제 요청하기(카카오페이)
 */
const requestKaKaoPayment = async (pg_data, setIsProcessing) => {
  const { merchant_uid, amount, buyer_name, buyer_tel } = pg_data;
  return new Promise((resolve) => {
    IMP.request_pay(
      {
        pg: "kakaopay.CA0567LK3F", // dev: TC0ONETIME , prd : CA0567LK3F
        merchant_uid: merchant_uid,
        name: "스핀업24",
        pay_method: "card",
        escrow: false,
        amount: amount,
        buyer_name: buyer_name,
        // buyer_email: "buyer@example.com",
        buyer_tel: buyer_tel,
        // buyer_addr: "성수이로 20길 16",
        // buyer_postcode: "04783",
        confirm_url: `${WEB_HOST}/spinup/order/confirm`,
        m_redirect_url: `${WEB_HOST}/reservation/verify`, // 모바일 환경에서 필수 입력
      },
      (response) => {
        resolve(response);
      }
    );
  });
};

/** d
 * 2. 결제 요청하기(네이버페이)
 */

const requestNaverPayment = (pg_data, setIsProcessing) => {
  const { merchant_uid, amount, buyer_name, buyer_tel } = pg_data;
  alert("준비중입니다.");
  setIsProcessing(false); // 처리 완료 후 처리 중 상태 해제
};

/**
 * 4. 본인인증 요청하기(다날)
 */

const requestDanalCertification = async (pg_data) => {
  const { merchant_uid, phone } = pg_data;
  return new Promise((resolve) => {
    IMP.certification(
      {
        // 주문 번호
        pg: "danal.B010008398", //본인인증 설정이 2개이상 되어 있는 경우 필
        merchant_uid: merchant_uid,
        phone: phone,
        // 모바일환경에서 popup:false(기본값) 인 경우 필수
        // m_redirect_url: `${WEB_HOST}/register/`,
        // PC환경에서는 popup 파라미터가 무시되고 항상 true 로 적용됨
        company: "https://spinup24.shop/",
        popup: false,
      },
      (response) => {
        resolve(response);
      }
    );
  });
};

export { initIamport, requestTossPayment, requestKaKaoPayment, requestNaverPayment, requestDanalCertification };
