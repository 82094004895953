class ReservationObj {
  courtId;
  id;
  startTime;
  endTime;
  constructor(data) {
    this.courtId = data.courtId;
    this.id = data.id;
    this.startTime = data.startTime;
    this.endTime = data.endTime;
  }
}

export default ReservationObj;
