import {
  Container as MapDiv,
  NaverMap,
  Marker,
  useNavermaps,
} from "react-naver-maps";

const SpinMap = () => {
  const navermaps = useNavermaps();

  return (
    <MapDiv
      style={{
        width: "100%",
        paddingTop: "100%",
      }}
    >
      <NaverMap
        defaultCenter={new navermaps.LatLng(37.525195, 126.8753456)}
        defaultZoom={15}
      >
        <Marker
          position={new navermaps.LatLng(37.525195, 126.8753456)}
          title={"스핀업24"}
          animation={1}
        />
      </NaverMap>
    </MapDiv>
  );
};

export default SpinMap;
